export default class EsignatureUtils {
  static READABLE_TYPE = {
    "App\\Models\\StructureNationale": "Structure Nationale",
    "App\\Models\\StructureLocale": "Structure Locale",
    "App\\Models\\Amo": "Amo",
  };
  static TYPE_FRONT_URL = {
    "App\\Models\\StructureNationale": "/structure-nationale",
    "App\\Models\\StructureLocale": "/structures-locales",
    "App\\Models\\Amo": "/amo",
  };

  static renderType = (type) => {
    return this.READABLE_TYPE[type] ?? type;
  };

  static getSignableFrontUrl = (signable_type, signable_id) => {
    let typeUrl = this.TYPE_FRONT_URL[signable_type] ?? null;

    if (typeUrl == null) {
      return null;
    }

    if (typeUrl === "/structure-nationale") {
      return typeUrl;
    }

    return `${typeUrl}/detail/${signable_id}`;
  };

  static renderSignable = (signable_type, signable_id, override = null) => {
    if (override != null) {
      return override;
    }

    let type = this.renderType(signable_type);
    if (type === "Structure Nationale") {
      return type;
    }

    return type + " (" + signable_id + ")";
  };
}
