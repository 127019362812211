export default class FileUtils {
  /**
   * Build a file and download it from an url. Compute the file name from the headers.
   *
   * @param {*} axiosInstance axios instance used for the call
   * @param {*} url url where the file content is
   * @param {*} options options used for the call
   * @param {string} fallbackName Name that will be used if none have been found in headers
   * @param {function} callback callback that is called on response, have the response as a parameter
   */
  static downLoadFromUrl(
    axiosInstance,
    url,
    options,
    fallbackName,
    callback = null,
  ) {
    axiosInstance.get(url, options).then((res) => {
      if (null != callback) {
        callback(res);
      }
      FileUtils.downLoadFile(res.data, res.headers, fallbackName);
    });
  }

  /**
   * Generates a file URL from the given content.
   *
   * @param {*} file The content of the downloaded file.
   * @param {string} contentType The MIME type of the file.
   */
  static getFileUrl(file, contentType) {
    const blob = new Blob([file], { type: contentType });
    return URL.createObjectURL(blob);
  }

  /**
   * Build a file and download it. Compute the file name from the headers.
   *
   * @param {*} file File content
   * @param {*} headers File headers to compute the filename
   * @param {string} fallbackName Name that will be used if none have been found in headers
   */
  static downLoadFile(file, headers, fallbackName = "file.txt") {
    const contentDisposition =
      headers["Content-Disposition"] || headers["content-disposition"];
    const contentType =
      headers["Content-Type"] || headers["content-type"] || "text/plain";
    const fileName = this.getFileName(contentDisposition, fallbackName);

    if (contentType.match(/utf-8/i) && typeof file === "string") {
      const universalBOM = "\uFEFF"; // this char ensure any software will open the file with utf-8 encoding
      file = universalBOM + file;
    }
    const url = this.getFileUrl(file, contentType);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    link.click();
    URL.revokeObjectURL(url);
  }

  /**
   * Extract the filename from a Content Disposition header. Return fallback name if it fails.
   *
   * @param {string} contentDisposition Content Disposition header
   * @param {string} fallbackName Name that will be used if none have been found in the header
   * @returns {string} Extracted filename or fallbackName if not found
   */
  static getFileName(contentDisposition, fallbackName = null) {
    // Try to extract filename with UTF-8 encoding from the header
    let res1 = /filename\*=utf-8''([^;]*)/.exec(contentDisposition);

    // If UTF-8 encoding extraction fails, try extracting without encoding
    if (!(res1 && res1[1])) {
      res1 = /filename=([^;]*)/.exec(contentDisposition);
    }

    // Decode and store the extracted filename or use the fallbackName if not found
    let ret = res1 && res1[1] ? decodeURI(res1[1]) : fallbackName;

    // Check if the filename is enclosed in double or single quotes and extract it
    let res2 = /(["'])(.*)\1/.exec(ret);

    // Return the extracted filename (if enclosed in quotes) or the original filename (if not)
    return res2 && res2[2] ? res2[2] : ret;
  }

  /**
   * Convert a JSON Array Buffer Response to an Object.
   *
   * @param {object} data response as Array Buffer
   */
  static getDataFromArrayBuffer(data) {
    if (null != data) {
      const decoder = new TextDecoder("utf-8");
      return JSON.parse(decoder.decode(data));
    }
    return null;
  }

  /**
   * Extracts the file extension from a given filename.
   *
   * @param {string} filename The name of the file from which to extract the extension.
   * @returns {string|null} The extracted file extension or null if no valid extension is found.
   */
  static getExtension(filename) {
    if (filename == null) {
      return null;
    }
    const dotIndex = filename.lastIndexOf(".");
    // Check if a dot was found and it's not at the beginning or end of the filename
    if (dotIndex !== -1 && dotIndex !== 0 && dotIndex !== filename.length - 1) {
      // Extract the substring starting from the dot to the end of the filename
      const extension = filename.substring(dotIndex + 1);
      return extension;
    } else {
      // No valid extension found
      return null;
    }
  }

  /**
   * Extracts the filename from a given file path.
   *
   * @param {string} filePath The path from which to extract the filename.
   * @returns {string} The extracted filename.
   */
  static getFilenameFromPath(filePath) {
    if (filePath == null) {
      return null;
    }
    // Split the path by the directory separator (either / or \)
    const parts = filePath.split(/[\\/]/);

    // The last part of the split array will be the filename
    const filename = parts[parts.length - 1];

    return filename;
  }
}
